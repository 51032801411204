@use "theme/variables" as *;

.c-field-switch {

    .checkbox-row {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        label.switch {
            height: $spacer-3;
            position: relative;
            user-select: none;
            width: $spacer-1 * 5;

            .slider {
                background-color: $grey-4;
                border-radius: $spacer-1 * 2;
                bottom: 0;
                cursor: pointer;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;

                &::before {
                    background: $grey-3;
                    border-radius: 50%;
                    bottom: $spacer-05;
                    content: "";
                    height: $spacer-1 * 2;
                    left: $spacer-05;
                    position: absolute;
                    transition: 0.3s ease;
                    width: $spacer-1 * 2;
                }
            }

            input {
                height: 0;
                opacity: 0;
                width: 0;

                &:focus + .slider {
                    box-shadow: none;
                }

                &:checked {

                    &:focus + .slider {
                        box-shadow: none;
                    }

                    + .slider {

                        &::before {
                            transform: translateX($spacer-1 * 2);
                        }
                    }
                }
            }
        }
    }

    &.type-default {

        .checkbox-row {

            label.switch {

                input {

                    &:checked {

                        + .slider {
                            background: $brand-3;

                            &::before {
                                background: $brand-6;
                            }
                        }
                    }
                }
            }
        }
    }

    &.type-success {

        .checkbox-row {

            label.switch {

                input {

                    &:checked {

                        + .slider {
                            background: $success-3;

                            &::before {
                                background: $success-6;
                            }
                        }
                    }
                }
            }
        }
    }
}
